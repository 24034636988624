
.contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.linkContainer {
    width: 30rem;
    display: flex;
    justify-content: space-between;
}

p {
    margin-top: 0;
}

.emailH2 {
    margin-top: 0;
}

.icon {
    height: 120px;
}

@media only screen and (max-width: 1200px) {
    .icon {
        height: 100px;
    }

    .linkContainer {
        width: 25rem;
    }

    .contactContainer h1 {
        font-size: 32px;
    }
}

@media only screen and (max-height: 800px) {
    .contactContainer h1 {
        font-size: 36px;
    }
}

@media only screen and (max-width: 500px) {
    .linkContainer img {
        height: 60px;
    }

    .linkContainer {
        width: 15rem;
    }

    .contactContainer {
        max-width: 20rem;
    }

    .contactContainer h1 {
        font-size: 26px;
    }
}
