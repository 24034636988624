
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1rem 0 2rem;
    width: 100%
}

.buttonContainer {
    display: flex;
}

img {
    height: 2.5rem;
}

.linkButton {
    color: var(--text);
    border-radius: 20px;
    border: none;
    font-family: var(--normal-text);
    width: 10rem;
    height: 2rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    margin: 0 0 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.linkButton:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--button);
    z-index: -2;
}
.linkButton:before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    transition: all 0.3s;
    border-radius: 10rem;
    background-color: var(--sections);
    z-index: -1;
}
.linkButton:hover:before {
    width: 100%;
}

.buttonDropDown {
    display: none;
}

@media only screen and (min-width: 1000px) {
    .dropdownContainer {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .buttonContainer {
        display: none;
    }

    .dropdownContainer {
        display: inline-flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .dropdownButton {
        color: var(--text);
        border-radius: 20px;
        border: none;
        font-family: var(--normal-text);
        width: 8rem;
        height: 2rem;
        position: relative;
        overflow: hidden;
        z-index: 1000;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        background-color: var(--button);
    }

    .dropdownContent {
        position: absolute;
        background-color: #f9f9f9;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        top: 60px;
        opacity: 0;
        width: 8rem;
        border-radius: 20px;
        font-family: var(--normal-text);
        overflow: hidden;
        z-index: 1;
        height: 0;
        transition: all 0.3s ease-in-out;
    }

    .dropdownContent.open {
        height: 132px;
        opacity: 1;
    }

    .dropdownLink {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
