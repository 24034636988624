
.projectsContainer {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
}

.relativeList {
    height: 100%;
    position: relative;
    max-width: 300px;
}

.projectsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background);
    border-radius: 20px 20px 0 0;
    height: 95%;
    width: 15rem;
    position: absolute;
    bottom: 0;
    left: 2.5rem;
}

.projectContainer {
    height: 100%;
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 170 170" xmlns="http://www.w3.org/2000/svg"><path fill="%234B5E76" d="M34.4,-54.9C46.7,-52.4,60.3,-47.5,59.5,-38C58.7,-28.4,43.5,-14.2,40.8,-1.6C38.1,11.1,47.8,22.2,50.6,35.1C53.3,48.1,49.1,62.9,39.5,72.5C29.9,82.2,15,86.5,3.6,80.3C-7.8,74.1,-15.6,57.3,-27.5,49.1C-39.4,40.8,-55.3,41,-67.4,34.2C-79.4,27.4,-87.5,13.7,-83.3,2.4C-79.2,-8.9,-62.7,-17.8,-53.7,-29.9C-44.8,-42,-43.4,-57.4,-35.7,-62.6C-28,-67.7,-14,-62.6,-1.5,-60C11,-57.4,22.1,-57.4,34.4,-54.9Z" transform="translate(102 72)" /></svg>');
    background-repeat: no-repeat;
    background-position: right;
}

p {
    font-family: var(--normal-text);
}

.project {
    position: relative;
    display: grid;
    visibility: hidden;
    grid-template-columns: auto 50%;

    height: 100%;
    max-height: 0;
    align-items: center;
    transform: translate(-20px, 0);
    opacity: 0;

    transition-duration: 0.4s;
    transition-timing-function:  ease-in-out;
    transition-property: opacity, transform;
}

.active {
    visibility: visible;
    opacity: 1;
    height:100%;
    max-height: 100%;
    transform: translate(0px, 0px);
}

.headerTextContainer h1 {
    margin: 0;
}

.project Img {
    height: auto;
    width: 70%;
    max-width: 300px;
    border-radius: 17px;
    justify-self: center;
    position: relative;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.headerTextContainer {
    margin: 0 0 0 5rem;
}

.pageContainer {
    display: flex;
}

.infoButton {
    display: none;
    margin: 0.5rem 0 1rem 0;
}

.modalContainer {
    background-color: var(--foreground);
    border-radius: 20px 20px 0 0;
    position: relative;
    margin: 0 auto 0 auto;
    height: 100%;
    z-index: 1000;
}

.modalProjectInfo {
    height: 90%;
    padding: 1rem;
    overflow: auto;
}

.modalProjectInfo button {
    float: right;
    color: var(--foreground);
    border-radius: 20px;
    border: none;
    font-family: var(--normal-text);
    height: 1.5rem;
    width: 1.5rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: var(--background);
    margin: 0 0 0.5rem 0.5rem;
    font-size: 20px;
}

.inactiveModalContainer {
    opacity: 0;
    position: relative;
    bottom: 0;
    transition: all 0.4s ease-in-out;
}

/* Styling for the visible state */
.activeModalContainer {
    opacity: 1;
    position: relative;
    bottom: 100%;
    transition: all 0.4s ease-in-out;
}

#upperModal {
    height: 100%;
}

.noshow {
    display: none;
}

.show {
    display: block;
}

.projectButton {
    color: var(--text);
    border-radius: 20px;
    border: none;
    font-family: var(--normal-text);
    width: 90%;
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    height: 2rem;
    margin: 1rem 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectButton:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--sections);
    z-index: -2;
}

.projectButton:before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    transition: all 0.3s;
    border-radius: 10rem;
    background-color: var(--button);
    z-index: -1;
}

.projectButton:hover:before {
    width: 100%;
}

.projectButton:hover {
    cursor: pointer;
}

.modalProjectInfo p {
    font-size: 18px;
    margin-right: 3rem;
    background-color: var(--sections);
    padding: 1rem;
    border-radius: 17px;
}

/*Media querys*/
@media only screen and (max-height: 800px) {
    .project Img {
        width: 38%;
    }

    .headerTextContainer h1 {
        font-size: 36px;
    }

    .headerTextContainer p {
        font-size: 26px;
    }
}

@media only screen and (min-width: 1000px) {
    .projectsContainer {
        grid-template-columns: 20% 80%;
        grid-template-rows: 100%;
    }
}

@media only screen and (max-width: 1100px) {
    .projectsList {
        width: 13rem;
    }
}

@media only screen and (max-width: 1000px) {
    .projectsList {
        display: none;
    }

    .infoButton {
        color: var(--foreground);
        border-radius: 20px;
        border: none;
        font-family: var(--normal-text);
        width: 10rem;
        height: 2rem;
        position: relative;
        overflow: hidden;
        z-index: 1;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        background-color: var(--background);
    }

    .relativeList {
        display: none;
    }

    .projectsContainer {
        display: block;
    }

    .headerTextContainer p {
        display: none;
    }

    .project {
        visibility: hidden;
        position: relative;

        height: 100%;
        max-height: 0;
        align-items: center;
        transform: translateY(-20px);
        opacity: 0;

        transition-duration: 0.4s;
        transition-timing-function:  ease-in-out;
        transition-property: opacity, transform;
        overflow: auto;
    }

    .active {
        visibility: visible;
        display: flex;
        flex-direction: column;
        opacity: 1;
        height: 100%;
        max-height: 100%;
        transform: translateY(0px);
    }

    .project img {
        width: 60%;
        height: auto;
    }

    .headerTextContainer {
        margin: 1rem 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .projectContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    #next {
        color: var(--foreground);
        background-color: var(--background);
        border-radius: 20px;
        border: none;
        position: absolute;
        top: 30px;
        right: 15px;
        font-weight: bold;
        height: 1.5rem;
        width: 1.5rem;
        font-size: 20px;
    }

    #prev {
        color: var(--foreground);
        background-color: var(--background);
        border-radius: 20px;
        border: none;
        position: absolute;
        top: 30px;
        left: 15px;
        font-weight: bold;
        height: 1.5rem;
        width: 1.5rem;
        font-size: 20px;
    }
}

@media only screen and (max-width: 380px) {
    .modalProjectInfo p {
        font-size: 18px;
    }
}



