
#vector {
    height: 35rem;
    width: 80%;
}

h1 {
    font-family: var(--header-text);
    color: var(--text);
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.25);
    /*font-size: 3rem;*/
}

p {
    font-family: var(--normal-text);
    font-weight: normal;
    color: var(--text);
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.25);
    /*font-size: 26px;*/
}

.picOfMe {
    height: 100%;
    width: auto;
    border-radius: 15px;
}

.imgContainer {
    height: 20rem;
    padding: 7px;
    background: var(--background);
    border-radius: 17px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    transition: all 0.4s ease-in-out;
}

.textContainer {
    width: 30rem;
    margin-right: 2rem;
}

.infoContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5%;
}

@media screen and (max-width: 900px){
    .infoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 5%;
        width: 80%;
    }

    .textContainer {
        text-align: center;
        max-width: 20rem;
        margin: 0;
    }

    .textContainer p {
        font-size: 18px;
    }

    .textContainer h1 {
        font-size: 28px;
    }

    .picOfMe {
        height: 14rem;
        width: auto;
        border-radius: 15px;
    }

    .imgContainer {
        height: auto;
        max-height: 14rem;
    }
}
