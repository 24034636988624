
.container {
  background-color: var(--background);
  display: grid;
  grid-template-rows: 10% 90%;
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.pageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80% 80%;
  height: 100%;
}

header {
  margin: 1rem 0 1rem 0;
  display: flex;
  flex-grow: 1;
}

.page {
  background-color: var(--foreground);
  margin: 0 1rem 0 1rem;
  border-radius: 20px 20px 0 0;
  height: 100%;
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
  height: 100%;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
  height: 100%;
}

.main {
  position: absolute;
  bottom: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}

@media screen and (max-width: 900px) {
  .pageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
  }

  .fadeIn {
    animation: 0.5s fadeIn forwards;
    height: 100%;
  }

  .fadeOut {
    animation: 0.5s fadeOut forwards;
    height: 100%;
  }

  .main {
    position: absolute;
    bottom: 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0px);
    }
    to {
      transform: translateY(-20px);
      opacity: 0;
    }
  }
}


