p {
    font-family: var(--header-text);
    font-weight: normal;
    color: var(--text);
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.25);
    /*white-space: pre;*/
    /*line-height: 1rem;*/
}

h2 {
    font-family: var(--header-text);
    color: var(--text);
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.25);
    /*font-size: 28px;*/
    margin-bottom: 0.5rem;
    text-align: center;
}

.current {
    margin-top: 0;
}

.learning {
    background-color: var(--background);
    width: 30rem;
    height: 10rem;
    border-radius: 17px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    justify-items: center;
    align-items: center;
}

.learning img, .known img {
    height: 70%;
    margin: 1rem;
}

.figma {
    grid-column: 3;
    grid-row: 1;
}

.react {
    grid-column: 2;
    grid-row: 1;
    max-height: 7rem;
}

.known {
    background-color: var(--background);
    width: 30rem;
    height: 20rem;
    border-radius: 17px;
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    justify-items: center;
    align-items: center;
}

.languagesAndFrameworks {
    margin: 0 2rem;
}

.intro {
    margin: 0 3rem 0 5rem
}
.infoContainer {
    margin: 0;
}


@media screen and (max-height: 800px) {
    h2 {
        font-size: 28px;
    }

    .intro p {
        font-size: 26px;
    }

    .learning {
        height: 8rem;
    }

    .known {
        height: 16rem;
    }

}

@media screen and (max-width: 900px) {
    .infoContainer {
        max-width: 500px;
    }

    .intro {
        font-family: var(--header-text);
        font-weight: normal;
        color: var(--text);
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.25);
        margin: 0;
    }

    .intro p {
        font-size: 18px;
    }

    .languagesAndFrameworks {
        max-width: 20rem;
        margin: 0;
    }

    h2 {
        font-size: 22px;
    }

    .learning {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        max-width: 20rem;
        height: auto;
    }

    .known {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-width: 20rem;
        height: auto;
    }

    .learning img, .known img {
        height: 60px;
        margin: 1rem;
    }
}


@media screen and (max-width: 380px) {
    .intro p {
        font-size: 14px;
    }

    .learning {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        max-width: 19rem;
        height: auto;
    }

    .known {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-width: 19rem;
        height: auto;
    }

    .learning img, .known img {
        height: 50px;
        margin: 1rem;
    }

    h2 {
        font-size: 18px;
    }
}


